<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      //   graph
      series: [
        {
          data: [0],
          name: 'Jumlah Pasien',
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
          ],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      // User Access
      btn: false,
    };
  },

  methods: {
    async get() {
      let data = await module.getDistributionGraphic(
        "patient-x-diagnoses/distribution/graphic"
      );

      if (data != null) {
        let series = data.series
        let patientSeries = [];
        console.log("graphic", data);

        // for (let a = 0; a < region.length; a++) {
        //   for (let b = 0; b < patient.length; b++) {
        //     if (region[a].id == patient[b].province_id) {
        //       patientSeries.push(patient[b].total_count);
        //     } else {
        //     //   if (b + 1 == patient.length) {
        //     //     patientSeries.push(0);
        //     //   }
        //     }
        //   }
        // }

        console.log("graphic data", patientSeries);

        this.series = [
          {
            data: series,
          },
        ];

        this.series = [
          {
            data: series,
          },
        ];

        console.log("graphic series", this.series);

        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: data.categories,
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
        };
        console.log("graphic chartpopti", this.chartOptions);
      }
    },
  },

  mounted() {
    // // BreadCrumb
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Pasien" },
    //   { title: "Peta Sebaran" },
    // ]);
    // Get Data
    this.get();
  },
};
</script>
